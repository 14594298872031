<script>
import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";
import axios from "axios";

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      doctors: [],
    };
  },
  methods: {
    findDoctor() {
      this.$router.push({ path: "/getdoctor" });
    },
    async getDoctor() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/top/doctor"
      );
      this.doctors = response.data;
      // console.log(this.doctors);
 
    },
    getAppointment(id) {
      this.$router.push("/appointment/" + id);
      console.log(id)
    },
    doctorChat(id) {
      this.$router.push("/chat/" + id);
      console.log(id)
    },
  },
  created() {
    this.getDoctor();
  },
};
</script>

<template>
  <Navbar />
  <section class="mainHeader">
    <!-- ==================header===================== -->
    <div class="header">
      <div class="hero-img">
        <div class="img-text">
          <p>The right care, is wherever you are.</p>
          <h1>Consult India’s Top Doctors Online, Safely From Home.</h1>
          <button class="consult-btn" @click="findDoctor">Consult Now</button>
        </div>
        <img src="../../assets/images/doctors.jpg" />
      </div>
    </div>

    <!-- ==================cards====================== -->
    <div class="mid">
      <h1>Meet Our Best Team</h1>
      <div class="cards">
        <div class="card-item" v-for="doctor in doctors" :key="doctor.id">
          <div class="card-img">
            <img src="../../assets/images/doctor2.jpg" />
          </div>
          <p>
            {{ doctor.name }} <span>{{ doctor.expert }} </span>
          </p>
          <div class="btns">
            <b-button
              variant="secondary"
              class="submitBtn"
              @click="getAppointment(doctor.id)"
              >Book Now
            </b-button>
            <b-button
              variant="success"
              class="submitBtn"
              @click="doctorChat(doctor.id)"
              >Talk now!
            </b-button>
          </div>
        </div>
      </div>
      <div class="specialities">
        <div class="speciality-box">
          <div class="box">
            <h1>25+</h1>
            <h3>Specialities</h3>
          </div>
          <div class="box">
            <h1>1000+</h1>
            <h3>Doctors</h3>
          </div>
          <div class="box" style="border: none">
            <h1>350+</h1>
            <h3>Hospitals</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<style>
body {
  background-color: white;
}
div.grid-div {
  height: 100%;
  margin: 0;
}
/* =======================HEADER======================= */
.header {
  position: relative;
}

.header .hero-img {
  height: 500px;
  overflow: hidden;
}

.header .hero-img img {
  width: 120%;
  /* height: auto; */
}

.hero-img .img-text {
  position: absolute;
  top: 120px;
  max-width: 30rem;
  margin-left: 3rem;
}

.hero-img .img-text p {
  animation-name: moveinleft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  font-size: 1rem;
}

.hero-img .img-text h1 {
  animation-name: moveinright;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  font-size: 2rem;
}

.hero-img .img-text button {
  animation-name: moveinbottom;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.consult-btn {
  margin-top: 5px;
  height: 50px;
  font-size: 0.9rem;
  border-radius: 5px;
  background-color: #1c8ec3;
  color: white;
  padding: 8px 16px;
  border: none;
  border: 1px solid transparent;
  transition: 0.2s ease;
}

.consult-btn:hover {
  border: 1px solid #2aa2db;
  background-color: transparent;
  color: #1c8ec3;
  transform: translateY(-5px);
  box-shadow: 0 5px 2px rgba(0, 0, 0, 0.5);
}

.consult-btn:active {
  transform: translateY(0);
  box-shadow: none;
}

/* ===========================Cards========================== */

.cards {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-row-gap: 15px;
  justify-content: space-around;
  align-items: center;
  padding: 0 2rem;
}

.card-item {
  height: 300px;
  width: 220px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.card-item:hover {
  transform: scale(1.04);
}
.card-item .btns{
  display: flex;
  gap: 10px;
}
.card-item .btns button{
  padding: 5px 10px;
  margin-bottom: 8px;
}
.card-item p {
  text-align: center;
  font-size: 18px;
  margin: 0 !important;
}

.card-item p span {
  display: block;
  color: #ec5a60;
  font-size: 14px;
}
.card-item b-button {
  width: fit-content;
  margin-bottom: 10px;
}
.card-img {
  height: 70%;
  width: 100%;
  overflow: hidden;
}

.card-img img {
  width: 100%;
  height: 100%;
}
.mid {
  padding: 20px 0 0 0;
}
.mid h1 {
  margin-top: 0rem;
  margin-left: 25px;
  color: #1c8ec3;
}

.specialities {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 170px;
  background-color: #e9f6ff;
  margin-top: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
}

.speciality-box {
  display: flex;
  padding: 5px;
  justify-content: center;
}
.speciality-box h1,
h3 {
  margin: 0;
  padding: 0;
}

.speciality-box h3 {
  font-weight: 600;
  font-size: 1.7rem;
}
.speciality-box .box {
  text-align: center;
  width: 400px;
  border-right: 1px solid black;
}

/* ===================================ANIMATION======================= */

@keyframes moveinleft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveinright {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveinbottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveinup {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1440px) {
  /* ===================================Header============================ */

  .header .hero-img {
    height: 340px;
    overflow: hidden;
  }

  .hero-img .img-text {
    top: 80px;
    max-width: 20rem;
    margin-left: 1rem;
  }

  .hero-img .img-text p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .hero-img .img-text h1 {
    font-size: 23px !important  ;
  }

  .consult-btn {
    height: 35px;
    font-size: 12px;
    padding: 5px 10px;
  }
  /* =======================================Cards================================ */
  .card-item {
    height: 245px;
    width: 170px;
  }

  .mid {
    padding: 10px 0 0 0;
  }
  .mid h1 {
    margin-left: 25px;
    margin-top: 0px;
    font-size: 25px;
  }
  .cards {
    /* height: 250px; */
    padding: 0;
  }

  .card-item p {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }

  .card-item p span {
    display: block;
    color: #ec5a60;
    font-size: 13px;
  }

  .specialities {
    height: 140px;
    margin-top: 25px;
    padding: 1rem;
    margin-bottom: 25px;
  }

  .speciality-box .box h1 {
    margin: 0;
    font-size: 25px;
  }
  .speciality-box .box h3 {
    font-size: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  /* ==============================Navbar */
  .header .hero-img {
    height: 260px;
  }

  .hero-img .img-text {
    top: 55px;
    max-width: 16rem;
    margin-left: 1rem;
  }

  .hero-img .img-text p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .hero-img .img-text h1 {
    font-size: 18px !important;
  }

  .consult-btn {
    height: 30px;
    font-size: 12px;
    padding: 5px 10px;
  }
  .mid h1 {
    font-size: 18px !important;
    margin-left: 10px;
  }

  .mid .cards {
    height: fit-content;
    display: grid;
    gap: 20px;
    padding: 10px;
    grid-template-columns: auto auto auto;
  }

  .card-item {
    height: 240px;
    width: 170px;
  }
  .card-item p {
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
  }
  .card-item p span {
    display: block;
    color: #ec5a60;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .header .hero-img img {
    width: 100%;
  }
  .hero-img .img-text {
    top: 125px;
    padding: 10px;
    text-align: center;
    margin: 0;
    min-width: 100%;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    background-color: #d1eefed8;
  }
  .hero-img .img-text p {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .hero-img .img-text h1 {
    font-size: 14px !important;
  }
  .header .hero-img {
    height: fit-content;
  }
  .consult-btn {
    height: 25px;
    font-size: 12px;
    padding: 2px 7px;
  }
  .mid h1 {
    font-size: 18px !important;
    margin-left: 10px;
  }

  .mid .cards {
    height: fit-content;
    display: grid;
    gap: 20px;
    padding: 10px;
    grid-template-columns: auto auto;
  }

  .card-item {
    height: 240px;
    width: 170px;
  }
  .card-item p {
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
  }
  .card-item p span {
    display: block;
    color: #ec5a60;
    font-size: 12px;
  }
  .speciality-box h3 {
    font-size: 20px;
  }
}

@media only screen and (min-device-width: 376px) and (max-device-width: 425px) {
  .header .hero-img img {
    width: 100%;
  }
  .hero-img .img-text {
    top: 125px;
    padding: 10px;
    text-align: center;
    margin: 0;
    min-width: 100%;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    background-color: #d1eefed8;
  }
  .hero-img .img-text p {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .hero-img .img-text h1 {
    font-size: 14px !important;
  }
  .header .hero-img {
    height: fit-content;
  }
  .consult-btn {
    height: 25px;
    font-size: 12px;
    padding: 2px 7px;
  }
  .mid h1 {
    font-size: 18px !important;
    margin-left: 10px;
  }

  .mid .cards {
    height: fit-content;
    display: grid;
    gap: 20px;
    padding: 10px;
    grid-template-columns: auto auto;
  }

  .card-item {
    height: 240px;
    width: 170px;
  }
  .card-item p {
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
  }
  .card-item p span {
    display: block;
    color: #ec5a60;
    font-size: 12px;
  }
  .speciality-box h3 {
    font-size: 20px;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 375px) {
  .header .hero-img img {
    width: 100%;
  }
  .hero-img .img-text {
    top: 125px;
    padding: 10px;
    text-align: center;
    margin: 0;
    min-width: 100%;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    background-color: #d1eefed8;
  }
  .hero-img .img-text p {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .hero-img .img-text h1 {
    font-size: 14px !important;
  }
  .header .hero-img {
    height: fit-content;
  }
  .consult-btn {
    height: 25px;
    font-size: 12px;
    padding: 2px 7px;
  }
  .mid h1 {
    font-size: 18px !important;
    margin-left: 10px;
  }

  .mid .cards {
    height: fit-content;
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: auto auto;
  }

  .card-item {
    height: 210px;
    width: 140px;
  }
  .card-item p {
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
  }
  .card-item p span {
    display: block;
    color: #ec5a60;
    font-size: 12px;
  }
  .speciality-box h3 {
    font-size: 20px;
  }
}
</style>
